export const ActivitySeverityType = {
  INFO: 'INFO',
  WARNINI: 'WARNING',
  ERROR: 'ERROR',
};

export const ActivityTypes = {
  REQUEST_INTERVIEW: {
    KEY: 'REQUEST_INTERVIEW',
    ACTIONS: {
      DEVELOPER_REQUEST_INTERVIEW_BUTTON_CLICK:
        'DEVELOPER_REQUEST_INTERVIEW_BUTTON_CLICK',
      DEVELOPER_REQUEST_INTERVIEW_SUBMIT: 'DEVELOPER_REQUEST_INTERVIEW_SUBMIT',
      REQUESTED_DEVELOPER_INTERVIEW: 'REQUESTED_DEVELOPER_INTERVIEW',
      REQUEST_INTERVIEW_FROM_SAVED_DEVELOPER:
        'REQUEST_INTERVIEW_FROM_SAVED_DEVELOPER',
      REQUEST_INTERVIEW_FROM_DDP_TOP: 'REQUEST_INTERVIEW_FROM_DDP_TOP',
      REQUEST_INTERVIEW_FROM_DDP_BOTTOM: 'REQUEST_INTERVIEW_FROM_DDP_BOTTOM',
      REQUEST_INTERVIEW_FROM_DEVELOPER_CARD:
        'REQUEST_INTERVIEW_FROM_DEVELOPER_CARD',
      SAVED_SUGGESTIONS_NUDGE_MODAL_OPEN: 'SAVED_SUGGESTIONS_NUDGE_MODAL_OPEN',
      SAVED_SUGGESTIONS_NUDGE_MODAL_SKIP_CLICKED:
        'SAVED_SUGGESTIONS_NUDGE_MODAL_SKIP_CLICKED',
      SAVED_SUGGESTIONS_NUDGE_MODAL_REQUEST_INTERVIEW_CLICKED:
        'SAVED_SUGGESTIONS_NUDGE_MODAL_REQUEST_INTERVIEW_CLICKED',
      CUSTOMER_SWITCHED_JOB_ON_PREFERENCES_MODAL:
        'CUSTOMER_SWITCHED_JOB_ON_PREFERENCES_MODAL',
      EXIT_INTERVIEW_CLICKED: 'EXIT_INTERVIEW_CLICKED',
      CONTINUE_REQUEST_CLICKED_ON_EXIT_NUDGE:
        'CONTINUE_REQUEST_CLICKED_ON_EXIT_NUDGE',
      BULK_REQUEST_INTERVIEW_CLICKED_FROM_SAVED_LIST:
        'BULK_REQUEST_INTERVIEW_CLICKED_FROM_SAVED_LIST',
      REQUESTED_DEVELOPER_INTERVIEW_BULK: 'REQUESTED_DEVELOPER_INTERVIEW_BULK',
    },
  },
  REQUEST_MORE_DEVELOPERS: {
    KEY: 'REQUEST_MORE_DEVELOPERS',
    ACTIONS: {
      REQUEST_MORE_DEVELOPERS_FORM_OPENED:
        'REQUEST_MORE_DEVELOPERS_FORM_OPENED',
      REQUEST_MORE_DEVELOPERS_SUBMIT: 'REQUEST_MORE_DEVELOPERS_SUBMIT',
      REQUEST_MORE_DEVELOPERS_CLOSE_WITHOUT_FILLING:
        'REQUEST_MORE_DEVELOPERS_CLOSE_WITHOUT_FILLING',
    },
  },
  DEVELOPER_PROFILE: {
    KEY: 'DEVELOPER_PROFILE',
    ACTIONS: {
      DEVELOPER_PROFILE_VIEW: 'DEVELOPER_PROFILE_VIEW',
      DEVELOPER_PROFILE_VIEW_SHARED: 'DEVELOPER_PROFILE_VIEW_SHARED',
      SHARE_BUTTON_CLICK: 'SHARE_BUTTON_CLICK',
      HOVERED_LCI_VIDEO: 'HOVERED_LCI_VIDEO',
      CLICKED_LCI_VIDEO: 'CLICKED_LCI_VIDEO',
      LCI_VIDEO_BROKEN: 'LCI_VIDEO_BROKEN',
      DEVELOPER_PROFILE_OPEN: 'DEVELOPER_PROFILE_OPEN',
      DEVELOPER_PROFILE_CLOSE: 'DEVELOPER_PROFILE_CLOSE',
      DEVELOPER_PROFILE_SECTION: 'DEVELOPER_PROFILE_SECTION',
      DEVELOPER_SKILL_HOVER: 'DEVELOPER_SKILL_HOVER',
      ACC_VIDEO_URL_NOT_FOUND: 'ACC_VIDEO_URL_NOT_FOUND',
      ACC_VIDEO_LESSER_VIDEO_LENGTH: 'ACC_VIDEO_LESSER_VIDEO_LENGTH',
      ACC_VIDEO_PLAYBACK_STARTED: 'ACC_VIDEO_PLAYBACK_STARTED',
      ACC_VIDEO_PLAYBACK_PAUSED: 'ACC_VIDEO_PLAYBACK_PAUSED',
      ACC_VIDEO_PLAYBACK_ENDED: 'ACC_VIDEO_PLAYBACK_ENDED',
    },
  },
  SIGN_UP: {
    KEY: 'SIGN_UP',
    ACTIONS: {
      SIGN_UP_PAGE: 'SIGN_UP_PAGE_VIEW',
      SIGN_UP_BUTTON_CLICK: 'SIGN_UP_BUTTON_CLICK',
      SIGN_IN_LINK_CLICK: 'SIGN_IN_LINK_CLICK',
      SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
      SIGN_UP_ERROR: 'SIGN_UP_ERROR',
      INVITE_LINK_INVALID: 'INVITE_LINK_INVALID',
    },
  },
  PACKET_AUTO_LOGIN: {
    KEY: 'PACKET_AUTO_LOGIN',
    ACTIONS: {
      AUTO_LOGIN_ERROR: 'AUTO_LOGIN_ERROR',
    },
  },
  GOOGLE_OAUTH: {
    KEY: 'GOOGLE_OAUTH',
    ACTIONS: {
      SUCCESS_TOKEN_ID: 'GOOGLE_OAUTH_SUCCESS_TOKEN_ID',
      FAILURE_TOKEN_ID: 'GOOGLE_OAUTH_FAILURE_TOKEN_ID',
      SUCCESS_SIGNUP: 'GOOGLE_OAUTH_SUCCESS_SIGNUP',
      FAILURE_SIGNUP: 'GOOGLE_OAUTH_FAILURE_SIGNUP',
      SUCCESS_SIGNIN: 'GOOGLE_OAUTH_SUCCESS_SIGNIN',
      FAILURE_SIGNIN: 'GOOGLE_OAUTH_FAILURE_SIGNIN',
    },
  },
  PAGE_VIEW: {
    KEY: 'PAGE_VIEW',
    ACTIONS: {
      MAIN_PAGE_VIEW: 'MAIN_PAGE_VIEW',
      HIDDEN_DEVELOPERS_PAGE_VIEW: 'HIDDEN_DEVELOPERS_PAGE_VIEW',
      SAVED_DEVELOPERS_PAGE_VIEW: 'SAVED_DEVELOPERS_PAGE_VIEW',
      SIDE_BAR_VIEW: 'SIDE_BAR_VIEW',
      INTERVIEW_REQUESTED_DEVELOPERS_PAGE_VIEW:
        'INTERVIEW_REQUESTED_DEVELOPERS_PAGE_VIEW',
      RECENTLY_VIEWED_DEVELOPERS_PAGE_VIEW:
        'RECENTLY_VIEWED_DEVELOPERS_PAGE_VIEW',
      MY_INTERVIEWS_PAGE_VIEW: 'MY_INTERVIEWS_PAGE_VIEW',
      HANDPICKED_PAGE_VIEW: 'HANDPICKED_PAGE_VIEW',
      CUSTOMER_PORTAL_DASHBOARD_PAGE_VIEW:
        'CUSTOMER_PORTAL_DASHBOARD_PAGE_VIEW',
      FIRST_CLICK: 'FIRST_CLICK',
    },
  },
  PAGE_EXIT: {
    KEY: 'PAGE_LEAVE',
    ACTIONS: {
      HANDPICKED_PAGE_EXIT: 'HANDPICKED_PAGE_EXIT',
    },
  },
  QUERY: {
    KEY: 'QUERY',
    ACTIONS: {
      QUERY_TRIGGERED: 'QUERY_TRIGGERED',
      QUERY_RESULT: 'QUERY_RESULT',
      QUERY_ERROR: 'QUERY_ERROR',
    },
  },
  SIGN_IN: {
    KEY: 'SIGN_IN',
    ACTIONS: {
      PAGE_VIEW: 'SIGN_IN_PAGE_VIEW',
      SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
      SIGN_IN_FAILED: 'SIGN_IN_FAILED',
    },
  },
  HIDDEN_DEVELOPERS: {
    KEY: 'HIDDEN_DEVELOPERS',
    ACTIONS: {
      GET_HIDDEN_DEVELOPERS: 'GET_HIDDEN_DEVELOPERS',
      HIDE_DEVELOPER: 'NOT_INTERESTED_IN_DEVELOPER',
      UNHIDE_DEVELOPER: 'INTERESTED_IN_DEVELOPER',
    },
  },
  SAVED_DEVELOPERS: {
    KEY: 'SAVED_DEVELOPERS',
    ACTIONS: {
      GET_SAVED_DEVELOPERS_DETAILED: 'GET_SAVED_DEVELOPERS_DETAILED',
      GET_SAVED_DEVELOPERS: 'GET_SAVED_DEVELOPERS',
      SAVE_DEVELOPER: 'SAVE_DEVELOPER',
      UPDATE_DEVELOPER_NOTE: 'UPDATE_DEVELOPER_NOTE',
      UNSAVE_DEVELOPER: 'UNSAVE_DEVELOPER',
      SEE_ALL_CLICK: 'SEE_ALL_SAVED_DEVELOPER_CLICK',
    },
  },
  HANDPICKED_DEVELOPERS: {
    KEY: 'HANDPICKED_DEVELOPERS',
    ACTIONS: {
      GET_HANDPICKED_DEVELOPERS: 'GET_HANDPICKED_DEVELOPERS',
      GET_HANDPICKED_DEVELOPERS_FOR_DASHBOARD:
        'GET_HANDPICKED_DEVELOPERS_FOR_DASHBOARD',
      OPEN_HANDPICKED_DEVELOPERS_PAGE: 'OPEN_HANDPICKED_DEVELOPERS_PAGE',
      INTERVIEW_CONFIREMD_HANDPICKED_DEVELOPERS:
        'INTERVIEW_CONFIREMD_HANDPICKED_DEVELOPERS',
      REJECTED_HANDPICKED_DEVELOPER: 'REJECTED_HANDPICKED_DEVELOPER',
      REJECTED_HANDPICKED_DEVELOPER_WITHOUT_FEEDBACK:
        'REJECTED_HANDPICKED_DEVELOPER_WITHOUT_FEEDBACK',
      REJECTED_HANDPICKED_DEVELOPER_WITH_FEEDBACK:
        'REJECTED_HANDPICKED_DEVELOPER_WITH_FEEDBACK',
      CLICKED_INTERVIEW_REQUEST: 'CLICKED_INTERVIEW_REQUEST',
      CLICKED_INTERVIEW_REQUEST_AVAILABILITY_NOT_CONFIREMD:
        'CLICKED_INTERVIEW_REQUEST_AVAILABILITY_NOT_CONFIREMD',
      HANDPICKED_PAGE_EXIT: 'HANDPICKED_PAGE_EXIT',
      POST_CONFIRM_AUTO_REDIRECTED_TO_SEARCH:
        'POST_CONFIRM_AUTO_REDIRECTED_TO_SEARCH',
      POST_CONFIRM_CLICKED_REDIRECT_TO_SEARCH:
        'POST_CONFIRM_CLICKED_REDIRECT_TO_SEARCH',
      POST_CONFIRM_CLOSED_BROWSER_TAB: 'POST_CONFIRM_CLOSED_BROWSER_TAB',
      POST_CONFIRM_CLOSED_POPUP_STAYED_IN_HANDPICKED:
        'POST_CONFIRM_CLOSED_POPUP_STAYED_IN_HANDPICKED',
      CLICKED_SEARCH_MORE_DEVELOPERS: 'CLICKED_SEARCH_MORE_DEVELOPERS',
    },
  },
  PACKET: {
    KEY: 'PACKET',
    ACTIONS: {
      REVIEW_PACKET_CLICK: 'REVIEW_PACKET_CLICK',
    },
  },
  INTERVIEW_REQUESTED_DEVELOPERS: {
    KEY: 'INTERVIEW_REQUESTED_DEVELOPERS',
    ACTIONS: {
      GET_INTERVIEW_REQUESTED_DEVELOPERS: 'GET_INTERVIEW_REQUESTED_DEVELOPERS',
      SEE_ALL_CLICK: 'SEE_ALL_INTERVIEW_REQUESTED_DEVELOPERS_CLICK',
    },
  },
  RECENTLY_VIEWED_DEVELOPERS: {
    KEY: 'RECENTLY_VIEWED_DEVELOPERS',
    ACTIONS: {
      OPEN_RECENTLY_VIEWED_DEVELOPERS_PAGE:
        'OPEN_RECENTLY_VIEWED_DEVELOPERS_PAGE',
      GET_RECENTLY_VIEWED_DEVELOPERS: 'GET_RECENTLY_VIEWED_DEVELOPERS',
    },
  },
  JOB_PREFERENCES: {
    KEY: 'JOB_PREFERENCES',
    ACTIONS: {
      JOB_PREFERENCES_EDITED: 'JOB_PREFERENCES_EDITED',
      JOB_PREFERENCES_SUBMITTED: 'JOB_PREFERENCES_SUBMITTED',
    },
  },
  DEVELOPER_NOT_FOUND: {
    KEY: 'DEVELOPER_NOT_FOUND',
    ACTIONS: {
      LOGIN_CLICK: 'DEVELOPER_NOT_FOUND_LOGIN_CLICK',
      BROWSE_MORE_DEVELOPERS_CLICK:
        'DEVELOPER_NOT_FOUND_BROWSE_MORE_DEVELOPERS_CLICK',
    },
  },
  SELF_SERVE_JOBS: {
    KEY: 'SELF_SERVE_JOBS',
    ACTIONS: {
      VIEW_SELF_SERVE_JOBS: 'VIEW_SELF_SERVE_JOBS',
    },
  },
  JOB: {
    KEY: 'JOB',
    ACTIONS: {
      POST_JOB: 'POST_JOB',
      POST_JOB_STARTED: 'POST_JOB_STARTED',
      POST_JOB_STEP1: 'POST_JOB_STEP1',
      POST_JOB_STEP2: 'POST_JOB_STEP2',
      POST_JOB_STEP3: 'POST_JOB_STEP3',
      POST_JOB_ADDITIONAL_DETAILS: 'POST_JOB_ADDITIONAL_DETAILS',
      EDIT_JOB: 'EDIT_JOB',
      EDIT_JOB_ADDITIONAL_DETAILS: 'EDIT_JOB_ADDITIONAL_DETAILS',
      SET_CONTINENTS_AGAINST_JOB: 'SET_CONTINENTS_AGAINST_JOB',
      ZERO_ACTIVE_JOBS: 'ZERO_ACTIVE_JOBS',
      DUPLICATE_JOB_CLICK: 'DUPLICATE_JOB_CLICK',
    },
  },
  NEW_NAVIGATION_USER_ACTIONS: {
    KEY: 'NEW_NAVIGATION_USER_ACTIONS',
    ACTIONS: {
      TAB_CLICK: 'TAB_CLICK',
    },
  },
  INVITE_COWORKER: {
    KEY: 'INVITE_COWORKER',
    ACTIONS: {
      MODAL_OPENED: 'MODAL_OPENED',
      INVITE_COWORKER_SUBMIT: 'INVITE_COWORKER_SUBMIT',
      RATE_SWITCH_TOGGLE: 'RATE_SWITCH_TOGGLE',
      JOB_IDS_UPDATE: 'JOB_IDS_UPDATE',
    },
  },
  INTERVIEW_NUDGE_ACTIONS: {
    KEY: 'INTERVIEW_NUDGE_ACTIONS',
    ACTIONS: {
      NUDGE_BANNER_DISPLAYED: 'NUDGE_BANNER_DISPLAYED',
      NUDGE_MODAL_DISPLAYED: 'NUDGE_BANNER_DISPLAYED',
      DONT_REMIND_ME_CLICKED: 'DONT_REMIND_ME_CLICKED',
    },
  },
  SHARE_DEVELOPER_BY_EMAIL: {
    KEY: 'SHARE_DEVELOPER_BY_EMAIL',
    ACTIONS: {
      SHARE_DEVELOPER_BY_EMAIL_CLICK: 'SHARE_DEVELOPER_BY_EMAIL_CLICK',
      SHARE_DEVELOPER_BY_EMAIL_SUBMIT: 'SHARE_DEVELOPER_BY_EMAIL_SUBMIT',
    },
  },
  NOT_INTERESTED: {
    KEY: 'NOT_INTERESTED',
    ACTIONS: {
      MARK_NOT_INTERESTED: 'MARK_NOT_INTERESTED',
      MARKED_INTERESTED: 'MARKED_INTERESTED',
    },
  },
  ASSISTED_SMART_SEARCH: {
    KEY: 'ASSISTED_SMART_SEARCH',
    ACTIONS: {
      ADD_KEYWORD_SEARCH_AUTO_COMPLETE_VALUE:
        'ADD_KEYWORD_SEARCH_AUTO_COMPLETE_VALUE',
      RELAX_FILTER_FIX_SKILL_REMOVED: 'RELAX_FILTER_FIX_SKILL_REMOVED',
      RELAX_FILTER_RESUME_KEYWORD_REMOVED:
        'RELAX_FILTER_RESUME_KEYWORD_REMOVED',
      RELAX_FILTER_YoE_REMOVED: 'RELAX_FILTER_YoE_REMOVED',
      RELAX_FILTER_BUDGET_REMOVED: 'RELAX_FILTER_BUDGET_REMOVED',
      RELAX_FILTER_CONTINENT_ADDED: 'RELAX_FILTER_CONTINENT_ADDED',
      SHOW_FEWER_SKILLS_KEYWORDS_FILTER_NUDGE_BANNER:
        'SHOW_FEWER_SKILLS_KEYWORDS_FILTER_NUDGE_BANNER',
      HIDE_FEWER_SKILLS_KEYWORDS_FILTER_NUDGE_BANNER:
        'HIDE_FEWER_SKILLS_KEYWORDS_FILTER_NUDGE_BANNER',
    },
  },
  LIMITED_ACCOUNT: {
    KEY: 'LIMITED_ACCOUNT',
    ACTIONS: {
      BOOK_A_CALL_CLICKED: 'BOOK_A_CALL_CLICKED',
      LIMITED_ACCESS_MODAL_OPENED: 'LIMITED_ACCESS_MODAL_OPENED',
      NATIVE_CALL_BOOKED: 'NATIVE_CALL_BOOKED',
    },
  },
  POSTMATCH: {
    KEY: 'POSTMATCH',
    ACTIONS: {
      VIEWED_POSTMATCH_ENGAGEMENTS: 'VIEWED_POSTMATCH_ENGAGEMENTS',
      CLICKED_POSTMATCH_DEVELOPER_PROFILE:
        'CLICKED_POSTMATCH_DEVELOPER_PROFILE',
      ENGAGEMENTS_LOADED: 'ENGAGEMENTS_LOADED',
      ENGAGEMENTS_EXPORTED: 'ENGAGEMENTS_EXPORTED',
    },
  },
};

export const REQUEST_SOURCE = {
  SearchCard: 'Search Developer Card',
  SearchDDP: 'Search DDP',
  SavedCard: 'Saved Developer Card',
  SavedDropdown: 'Saved Developers Dropdown',
  SavedDDP: 'Saved DDP',
  RecentCard: 'Recent Developer Card',
  RecentDDP: 'Recent DDP',
  SharedDDP: 'Shared DDP',
  HandpickedDDP: 'Handpicked DDP',
  ViewSavedDeveloperList: 'Saved Developers List',
  HiddenDDP: 'Hidden Developer Card',
  InterviewRequestedDevelopers: 'InterviewRequestedDevelopers',
  // SFSV-1985b: should we mantain this?
  RecommendedInMainPage: 'RECOMMENDED_IN_MAIN_PAGE',
  MyInterviewsPage: 'MY_INTERVIEWS_PAGE',
  JobInterviewsPage: 'JOB_INTERVIEWS_PAGE',
  JobPage: 'JOB_PAGE',
  HandpickedList: 'HANDPICKED_LIST',
  CustomerPortalDashboardSavedCard: 'CUSTOMER_PORTAL_DASHBOARD_SAVED_CARD',
  CustomerPortalDashboard: 'CUSTOMER_PORTAL_DASHBOARD',
  CustomerPortalDashboardInterviewCard:
    'CUSTOMER_PORTAL_DASHBOARD_INTERVIEW_CARD',
  BULK_IR_FROM_FROM_SAVED_LIST: 'bulk-ir-from-saved-list',
  BULK_IR_FROM_PACKET: 'bulk-ir-from-packet',
  SearchPageDevCard: 'SEARCH_PAGE_DEV_CARD', // job search page list
  DeveloperProfile: 'DEVELOPER_PROFILE', // ddp
  NotInterestedTabDevCard: 'NOT_INTERSESTED_TAB_DEV_CARD',
  EngagementsDashboard: 'Engagements Dashboard',
};

export const REQUEST_SIGNUP_SOURCE = {
  WithoutRecommendedSignup: 'Without Recommended Signup',
};
