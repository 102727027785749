import { AppFlags, overrideAppFlags } from './appFlags';
import { getGBUserFlags } from './growth-book';

export function getUserOptions() {
  const getAppFlagValue = (flagType, defaultValue) => {
    if (overrideAppFlags[flagType] !== undefined) {
      return overrideAppFlags[flagType];
    }

    return getGBUserFlags()[flagType];
  };

  const enableSearchDebug = getAppFlagValue(AppFlags.EnableSearchDebug, false);

  const enableGoogleCalendarForCustomers = getAppFlagValue(
    AppFlags.EnableGoogleCalendarForCustomers,
    false,
  );

  const selfServeJobPostOptions = getAppFlagValue(
    AppFlags.SelfServeJobPostOptions,
    {},
  );

  const enableInviteCoworkersRates = getAppFlagValue(
    AppFlags.EnableInviteCoworkersRates,
    false,
  );

  const enableInviteCoworkersHBT = getAppFlagValue(
    AppFlags.EnableInviteCoworkersHBT,
    false,
  );

  const enableAutoPopulateFiltersFromJob = getAppFlagValue(
    AppFlags.EnableAutoPopulateFiltersFromJob,
    false,
  );

  const enableSFSV2136EnableJobsHomepage = getAppFlagValue(
    AppFlags.EnableSFSV2136EnableJobsHomepage,
    false,
  );

  const enableSFSV2135ShowCreateJobCtaOnJobsPage = getAppFlagValue(
    AppFlags.EnableSFSV2135ShowCreateJobCtaOnJobsPage,
    false,
  );

  const enableAfterJobEditRedirect = getAppFlagValue(
    AppFlags.EnableAfterJobEditRedirect,
    false,
  );

  const enableMoreFiltersDefaultToOpen = getAppFlagValue(
    AppFlags.EnableMoreFiltersDefaultToOpen,
    false,
  );

  const enableNativeBookingCallExperience = getAppFlagValue(
    AppFlags.EnableNativeBookingCallExperience,
    false,
  );

  const enableJobFlowV2 = getAppFlagValue(AppFlags.EnableJobFlowV2, false);

  const enablePostmatch = getAppFlagValue(AppFlags.EnablePostmatch);

  const sfsv2246EnableEditJobV2 = getAppFlagValue(
    AppFlags.SFSV2246EnableEditJobV2,
    false,
  );

  const sfsv2273EnableSkillsDedup = getAppFlagValue(
    AppFlags.SFSV2273EnableSkillsDedup,
    false,
  );

  const sfsv2348EnableStarDestroyer = getAppFlagValue(
    AppFlags.SFSV2348EnableStarDestroyer,
    false,
  );

  const post322EnableHoursBilledInSummary = getAppFlagValue(
    AppFlags.POST322EnableHoursBilledInSummary,
    false,
  );

  const post323EnableHoursWorkedColumn = getAppFlagValue(
    AppFlags.POST323EnableHoursWorkedColumn,
    false,
  );

  const sfsv2397EnableNewDeveloperHeader = getAppFlagValue(
    AppFlags.SFSV2397EnableNewDeveloperHeader,
    false,
  );

  return {
    enableSearchDebug,
    enableGoogleCalendarForCustomers,
    selfServeJobPostOptions,
    enableInviteCoworkersRates,
    enableInviteCoworkersHBT,
    enableAutoPopulateFiltersFromJob,
    enableSFSV2136EnableJobsHomepage,
    enableSFSV2135ShowCreateJobCtaOnJobsPage,
    enableAfterJobEditRedirect,
    enableMoreFiltersDefaultToOpen,
    enableNativeBookingCallExperience,
    enableJobFlowV2,
    enablePostmatch,
    sfsv2246EnableEditJobV2,
    sfsv2273EnableSkillsDedup,
    sfsv2348EnableStarDestroyer,
    post322EnableHoursBilledInSummary,
    post323EnableHoursWorkedColumn,
    sfsv2397EnableNewDeveloperHeader,
  };
}

export const InviteSource = {
  Auto: 'auto',
  Manual: 'manual',
};

export const InviteStatus = {
  Created: 'created',
  Waiting: 'waiting',
  NoSend: 'no-send',
  Sent: 'sent',
  Error: 'error',
  Revoked: 'revoked',
  Expired: 'expired',
  Registered: 'registered',
};
